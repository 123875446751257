import { useState, useEffect, lazy, Suspense, useTransition } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { getPersistor } from '@rematch/persist'
import { Route, Routes, Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import UserLayout from './components/layout/User'
import LoginLayout from './components/layout/Login'
import ProtectedRoute from './components/routes/Protected'
import { store } from './redux/store'
import { ConfigProvider, Spin } from 'antd'
import './assets/styles/main.css'
import './assets/styles/responsive.css'
import { extractIdToken, getUserRoleFromToken, setRouterHistory } from './utils'
import WizrFlexLayout from './components/WizrFlexLayout'
import { WizrModalProvider } from './providers/modal/WizrModalProvider'
import { WizrNotificationProvider } from './providers/notification/NotificationProvider'

import { ReactFlowProvider } from 'reactflow'

import jwt_decode from 'jwt-decode'


import { usePermission } from 'react-permission-role'
import { dispatchSetPermissionValues } from './redux/actions/content'
import { AuthProvider } from './authContext'
import ExtensionRedirectScreen from './pages/ExtensionRedirectScreen'

// {
//   components: {
//     Button: {
//        colorPrimaryBorderHover: 'red',
//        colorPrimaryHover: 'lightgray',
//        colorPrimary: 'red',
//        colorPrimaryActive: 'lightgray',
//        colorPrimaryTextHover: 'lightgray',
//     }
//   }
// }

const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ChangePassword = lazy(() => import('./pages/ChangePassword'))
const DashboardPage = lazy(() => import('./pages/Dashboard'))
const Folders = lazy(() => import('./pages/Folders'))
const Files = lazy(() => import('./pages/Files'))
const Prompt = lazy(() => import('./pages/PromptList'))
const PromptView = lazy(() => import('./pages/PromptView'))
const Settings = lazy(() => import('./pages/Settings'))
const AppDesigner = lazy(() => import('./pages/AppDesigner'))
const AppDesignerTool = lazy(() => import('./pages/AppDesignerTool'))
const History = lazy(() => import('./pages/PromptHistory'))
const AppHistory = lazy(() => import('./pages/AppHistory'))
const AppShare = lazy(() => import('./pages/AppShare'))
const BulkRun = lazy(() => import('./pages/BulkRun'))
const AppInsights = lazy(() => import('./pages/AppInsights'))
const RunHistory = lazy(() => import('./pages/RunHistory'))
const EnterpriseContent = lazy(() => import('./pages/EnterpriseContent'))
const EndUserAppListing = lazy(() => import('./pages/EndUserAppListing'))
const EndUserHistory = lazy(() => import('./pages/EndUserHistory'))
const BulkRunList = lazy(() => import('./pages/BulkRunsList'))
const TenantDetails = lazy(() => import('./pages/components/SettingsComponents/TenantDetails'))
const UserDetail = lazy(() => import('./pages/components/SettingsComponents/UserDetail'))
const CxSettings = lazy(() => import('./pages/CxSettings'))
const PastTickets = lazy(() => import('./pages/PastTickets'))
const FAQ = lazy(() => import('./pages/FAQ'))
const GroupListing = lazy(() => import('./pages/components/CXSettingsComponents/GroupListing'))
const DataListingGroup = lazy(() => import('./pages/components/CXSettingsComponents/DataListingGroup'))
const NewFeatureScreen = lazy(() => import('./pages/NewFeatureScreen'))
const Company = lazy(() => import('./pages/components/CXSettingsComponents/Company'))
const FeatureTest = lazy(() => import('./pages/components/CXSettingsComponents/FeatureTest'))
const Tickets = lazy(() => import('./pages/tickets'))
const EndPoint = lazy(() => import('./pages/components/CXSettingsComponents/EndPoint'))
const AutoTag = lazy(() => import('./pages/components/CXSettingsComponents/AutoTag'))
const AgentAssist = lazy(() => import('./pages/components/CXSettingsComponents/AgentAssist'))
const ControlRoom = lazy(() => import('./pages/CxControlRoom'))
const CreateRoles = lazy(() => import('./pages/components/SettingsComponents/CreateRoles'))
const UpdateProduct = lazy(() => import('./pages/components/SettingsComponents/UpdateProduct'))
// const ExtensionRedirectScreen = lazy(() => import('./pages/ExtensionRedirectScreen'))
const ChatBot = lazy(() => import('./pages/ChatBot'))
const ChatBotListing = lazy(() => import('./pages/ChatBotListing'))
const AutoSolve = lazy(() => import('./pages/components/CXSettingsComponents/AutoSolve'))
const AutoSolveChatBot = lazy(() => import('./pages/components/CXSettingsComponents/AutoSolveComponents.tsx/AutoSolveChatBot'))
const WidgetCreation = lazy(() => import('./pages/components/CXSettingsComponents/Widgets'))
const CreateAgent = lazy(() => import('./pages/components/CXSettingsComponents/AutoSolveComponents.tsx/CreateAgent'))
const WizrNoAccessScreen = lazy(() => import('./pages/errorPage'))
const CustomerInteractions = lazy(() => import('./pages/CustomerInteractions'))
// const WebFormatLargePreview = lazy(() => import('./pages/components/CXSettingsComponents/AutoSolveComponents.tsx/WebformatlargePreviewComponents/WebFormatLargePreview'))
const TestEmbed = lazy(() => import('./pages/components/CXSettingsComponents/AutoSolveComponents.tsx/WebChatLargePreviewScreen'))
//const AgentAssist2 = lazy(()=>import('./pages/components/CXSettingsComponents/AgentAssist2'))
const HelpAndSupport = lazy(() => import('./pages/HelpAndSupport'))
const AppInsightsWhole = lazy(() => import('./pages/components/AppInsightsWholes'))
const AgentTag = lazy(() => import('./pages/components/CXSettingsComponents/AgentTag'))
const WidgetHistory = lazy(() => import('./pages/WidgetHistory'))
const WebChatSmallPreview = lazy(() => import('./pages/components/CXSettingsComponents/AutoSolveComponents.tsx/WebChatSmallPreviewScreen'))

const wizrTheme = {
  components: {
    Switch: {
      colorPrimary: '#22C55E',
    },
    Button: {
      // colorPrimary: 'background: var(--grayscale-800, #000000)',
      colorPrimaryHover: '#5D5F62',
    },
  },
  token: {
    colorPrimary: 'background: var(--grayscale-800, #1D1F22)',
  },
}

interface ITokenDecoded {
  isForgotPassword: boolean
}
const App = (): JSX.Element => {
  const { setUser } = usePermission()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    Boolean(localStorage.getItem('id_token')),
  )
  const [isUserLayout, setIsUserLayout] = useState<boolean>(
    Boolean(localStorage.getItem('id_token')),
  )


  const [userRole, setUserRole] = useState<any>('admin')
  const location = useLocation()

  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition()


  const FrameBuster: React.FC = () => {
    useEffect(() => {
      if (window.top && window.top !== window.self) {
        // window.top.location.href = window.self.location.href;
        navigate('/login');
      }
    }, []);

    return null;
  };

  useEffect(() => {
    const handleHash = () => {
      const hash = window.location.hash;
      if (hash.startsWith('#error')) {
        // Parse the error information
        const params = new URLSearchParams(hash.slice(1));
        const error = params.get('error');
        const errorDescription = params.get('error_description');

        // Handle the error (e.g., show an error message)
        // For now, we'll just redirect to the home page
        navigate('/home');
      }
    };

    handleHash();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHash);

    return () => {
      window.removeEventListener('hashchange', handleHash);
    };
  }, [navigate]);



  useEffect(() => {
    if (location?.hash?.includes('id_token')) {
      const idToken = extractIdToken(location.hash)
      const userDetails = getUserRoleFromToken(idToken)
      let isForgotPassword = false
      const tokenDecoded: any = jwt_decode<ITokenDecoded>(idToken)
      if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

        // const tokenDecodedSplitted =tokenDecoded.iss
        // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
        // tenantId  = tokenDecodedSplitted.match(uuidpattern);
        isForgotPassword = tokenDecoded.isForgotPassword
        //tenantId = tokenDecodedSplitted?.[3]
      }
      startTransition(() => {

        if (location?.pathname?.includes('extension')) {
          if(isForgotPassword){
            navigate('/extension/login')
            setIsUserLayout(true)
          }
          else{
            localStorage.setItem('extension_id_token', idToken)
            navigate('/extension')
            setIsUserLayout(true)
          }
        }
        else if (isForgotPassword) {
          navigate('/login')
        }
        else {
          localStorage.setItem('id_token', idToken)
          if (idToken) {
            //set permissions in redux
            // getAccesList(idToken)
            dispatchSetPermissionValues(idToken)
          }
          setIsAuthenticated(Boolean(localStorage.getItem('id_token')))
          setIsUserLayout(true)
          if (userDetails === 'test_enduser') {
            navigate('/euserapplisting')
          }
          else if (localStorage.getItem('reset') !== 'true') {
            navigate('/home')
          } else {
            navigate('/login')
          }
        }
      })
    }
  }, [location])
  
  setRouterHistory({ navigate, location })

  const AppLayout = isUserLayout ? UserLayout : LoginLayout

  useEffect(() => {
    // If authenticated and on root, navigate to home
    if (isAuthenticated && (location.pathname === '/' || location.pathname === '')) {
      navigate('/home');
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <WizrFlexLayout>
      <ConfigProvider theme={wizrTheme}>
        <Provider store={store}>
          <AuthProvider>
            <PersistGate loading={<Spin />} persistor={getPersistor()}>
              <WizrModalProvider>
                <WizrNotificationProvider>
                  <ReactFlowProvider>
                    <FrameBuster />

                    <Suspense fallback={<UserLayout >
                      <WizrFlexLayout justifyContent='center'>
                        {/* <Skeleton active /> */}
                        <Spin />
                      </WizrFlexLayout>

                    </UserLayout>}>
                      {isPending ? (
                        // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        //   <Spin size="large" />
                        // </div>
                        <UserLayout>
                          <></>
                        </UserLayout>
                      ) : (
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <AppLayout>
                                {/* <ProtectedRoute isSignedIn={isAuthenticated}> */}
                                <Outlet />
                                {/* </ProtectedRoute> */}
                              </AppLayout>
                            }
                          >
                            <Route path="/" element={<Navigate to="home" replace />} />
                            <Route path='login' element={<Login />} />
                            <Route path='error' element={<WizrNoAccessScreen />} />

                            <Route path='' element={
                              <ProtectedRoute isSignedIn={isAuthenticated}>
                                <DashboardPage />
                              </ProtectedRoute>
                            } />
                            <Route path='forgot-password' element={<ForgotPassword />} />
                            <Route path='change-password' element={<ChangePassword />} />
                            <Route path='extension' element={<ExtensionRedirectScreen />} />
                            <Route path='extension/login' element={<ExtensionRedirectScreen />} />

                            <Route
                              path='home'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <DashboardPage />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='folder'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <Folders />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='folder/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                   <Files /> 
                                   {/* <KnowledgeBase/> */}

                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='notes'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <></>
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='prompt'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <Prompt />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='prompt-view'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <PromptView />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='settings'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <Settings />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='app-designer'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AppDesigner />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='app-designer/tool/:id?/:appId?/:action?'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AppDesignerTool />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='prompt-history/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <History />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='app-history/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AppHistory />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='appshare/:id/:token'
                              element={
                                // <ProtectedRoute isSignedIn={isAuthenticated}>
                                <AppShare />
                                // </ProtectedRoute>
                              }
                            />
                            <Route
                              path='bulkrun'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <BulkRun />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='appInsights/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AppInsights />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='runhistory/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <RunHistory />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='home'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <DashboardPage />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='enduserapp'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <EnterpriseContent />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='euserapplisting'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <EndUserAppListing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='euserhistory'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <EndUserHistory />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='bulkrunlist/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <BulkRunList />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='tenantdetails'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <TenantDetails />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='userdetails'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <UserDetail />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='cxsettings'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <CxSettings />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='cxsettings/:type'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <CxSettings />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='cxsettings/:type/new'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <NewFeatureScreen />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='cxcontrol'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <ControlRoom />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='past_tickets'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  {/* <NewFeatureScreen /> */}
                                  <PastTickets />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='past_tickets/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <Tickets />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='faq/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <Tickets />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='faq'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <FAQ />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='featuregroup/:title'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <GroupListing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='featuregroup/:title/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <DataListingGroup />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='company'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <Company />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path='featuretest'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <FeatureTest />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='autotag/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AutoTag />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='agentassist/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AgentAssist />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='autosolve/:id/:botId?'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AutoSolve />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='endpoint'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <EndPoint />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path='userroles'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <CreateRoles />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path='product'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <UpdateProduct />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path='chatbot/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <ChatBot />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='autosolvechatbot/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AutoSolveChatBot />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='chatbotlisting'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <ChatBotListing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='widgets/:type'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <WidgetCreation />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='createAgent/:id'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <CreateAgent />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='customerInteractions'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <CustomerInteractions />
                                </ProtectedRoute>
                              }
                            />
                            {/* <Route
                              path='webFormatLarge/:id'
                              element={
                                <WebFormatLargePreview />
                              }
                            /> */}
                            <Route
                              path='appInsights'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <AppInsightsWhole />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path='testEmbed'
                              element={
                                <TestEmbed />
                              }
                            />

                            <Route
                              path='agenttag/:id'
                              element={
                                <AgentTag />
                              }
                            />

                            <Route path="*" element={<Navigate to="/home" replace />} />
                            <Route
                              path='widgetHistory'
                              element={
                                <ProtectedRoute isSignedIn={isAuthenticated}>
                                  <WidgetHistory />
                                </ProtectedRoute>
                              }
                            />
                     
                        <Route
                          path='chatbot/:id'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <ChatBot />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='autosolvechatbot/:id'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <AutoSolveChatBot />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='chatbotlisting'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <ChatBotListing />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='widgets/:type'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <WidgetCreation />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='createAgent/:id'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <CreateAgent />
                            </ProtectedRoute>
                          }
                        />
                        
                        {/* <Route
                          path='agentassist2/:id'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <AgentAssist2/>
                            </ProtectedRoute>
                          }
                        /> */}


                        <Route
                          path='customerInteractions'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <CustomerInteractions />
                            </ProtectedRoute>
                          }
                        />
                        {/* <Route
                          path='webFormatLarge/:id'
                          element={
                            <WebFormatLargePreview />
                          }
                        /> */}
                          <Route
                          path='appInsights'
                          element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                              <AppInsightsWhole />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='testEmbed/:id'
                          element={
                            <TestEmbed />
                          }
                        />
                        <Route
                          path='help'
                          element={
                            <HelpAndSupport />
                          }
                        />
                        <Route
                          path='agenttag/:id'
                          element={
                            <AgentTag />
                          }
                        />
                         <Route
                          path='webChatSmallPreview/:id'
                          element={
                            <WebChatSmallPreview />
                          }
                        />
                          <Route path="*" element={<Navigate to="/home" replace />} />


                          </Route>
                    
                    </Routes>
                     )}
                    </Suspense>
                  </ReactFlowProvider>
                </WizrNotificationProvider>
              </WizrModalProvider>
            </PersistGate>
          </AuthProvider>
        </Provider>
      </ConfigProvider>
    </WizrFlexLayout>
  )
}

export default App