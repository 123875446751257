import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons';
import { BlobServiceClient } from '@azure/storage-blob'
import jwt_decode from 'jwt-decode'
import { token } from 'stylis'
import { format } from 'date-fns';
import { store } from './redux/store'


let routerHistory: { [key: string]: any } = {
  navigate: null,
  location: null,
}
interface ITokenDecoded {
  iss: string
}

const extractIdToken = (tokenString = '') => tokenString.slice(tokenString.indexOf('=') + 1)

const convertBytestoMB = (sizeInBytes: number) =>
  sizeInBytes ? `${(sizeInBytes / 1024 ** 2)?.toFixed(2)} MB` : '0 MB'

const setRouterHistory = (history: any) => (routerHistory = history)
const generateAzureUploadUrl = (tenantId: string) => {
  const azureSASUrl =
    process.env.REACT_APP_AZURE_FILE_CONTAINER_URL +
    '/' +
    tenantId +
    '?' +
    process.env.REACT_APP_AZURE_FILE_CONTAINER_SAS_TOKEN
  return azureSASUrl
}

const downloadBlob = async (folderId: any, fileData: any) => {
  try {
    //const blobServiceClient = new BlobServiceClient(process.env.REACT_APP_AZURE_SAS_URL || '')
    const blobServiceClient = new BlobServiceClient(
      generateAzureUploadUrl('d8971718-5aad-480c-8dd0-eecf50e93a93') || '',
    )
    const containerClientUrl = `/inarchive/${folderId}`
    const containerClient = blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(fileData?.file_name)

    const response = await blobClient.download()

    const blob = await response.blobBody
    return blob
  } catch (error) {
    console.error('Error downloading blob:', error)
  }
}

const statusChecker = async (url: string) => {
  const checkStatus = async (): Promise<any> => {
    const statusResponse = await fetch(url)
    const statusResponseJson = await statusResponse.json()
    if (statusResponseJson.runtimeStatus === "Running" || statusResponseJson.runtimeStatus === "Pending") {
      await new Promise((resolve) => setTimeout(resolve, 5000))
      const value = await checkStatus()
      return value
    } else {
      localStorage.setItem('prompt_history_id', statusResponseJson?.output?.prompt_history_id)
      return statusResponseJson?.output
    }
  }
  if (url) {
    const response = await checkStatus()
    return response
  }
}

const PaginationLimit = 25
const getValueFromLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key)
  if (localStorageValue !== null && localStorageValue !== '') {
    try {
      return JSON.parse(localStorageValue)
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error)
      return null
    }
  } else {
    return null
  }
}

const addValueToLocalStorage = (key: string, jsonValue: any) =>
  localStorage.setItem(key, JSON.stringify(jsonValue))

const uploadToLocalStorage = (filesNames: any) => {
  const excistingFiles = getFromLocalStorage()
  if (excistingFiles?.length > 0) {
    const updatedFiles = [...excistingFiles, ...filesNames]
    localStorage.setItem('filesToUpload', JSON.stringify(updatedFiles))
  } else {
    localStorage.setItem('filesToUpload', JSON.stringify(filesNames))
  }
}

const getAccessLists = (token: string): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    try {
      const tokenDecoded: any = jwt_decode<ITokenDecoded>(token);

      if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {
        const tokenUserAccessList = tokenDecoded?.extension_UserAccess;
        const arrayOfPermissions = tokenUserAccessList.split(", ");
        resolve(arrayOfPermissions);
      } else {
        resolve([]); // or resolve with any default value
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      reject(error); // Reject the promise with the error
    }
  });
};

const getTenantId = () => {
  let tenantId
  const token = localStorage.getItem('id_token')

  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_TenantId
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getTenantIdwithtokenId = (token: any) => {
  let tenantId
  // const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_TenantId
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getUserRole = () => {
  let tenantId
  const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_UserRole
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getUserRoleFromToken = (token: any) => {
  let tenantId
  // const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_UserRole
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getTenantNameFromToken = (token: any) => {
  let tenantId
  // const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_UserRole
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}



const getFromLocalStorage = () => {
  const data = JSON.parse(localStorage.getItem('filesToUpload') || ' {}')
  return data
}
const getFileType = (file_name: string) => {
  try {
    const [, file_extension] = file_name.split('.')
    const lowerCaseExtension = file_extension.toLowerCase()

    const fileTypes: Record<string, string> = {
      '.txt': 'TEXT',
      '.pdf': 'PDF',
      '.csv': 'CSV',
      '.docx': 'WORD',
      '.doc': 'WORD',
      '.ppt': 'POWERPOINT',
      '.pptx': 'POWERPOINT',
      '.xls': 'EXCEL',
      '.xlsx': 'EXCEL',
      '.json': 'JSON',
      // Add more file types and extensions as needed
    }

    return fileTypes[`.${lowerCaseExtension}`] || 'Unknown'
  } catch (e) {
    console.error(`An error occurred: ${e}`)
    throw e
  }
}

function generateNodeSequenceResponse(req: any) {

  const inputEdges = req.edges;

  const mapping = inputEdges.reduce((result: any, item: any) => {
    result[item.source] = item.target;
    return result;
  }, {});

  const nodeSequence = [];
  let current_node = 'start';
  while (current_node in mapping) {
    nodeSequence.push(current_node);
    current_node = mapping[current_node];
  }
  nodeSequence.push(current_node);

  return nodeSequence;
}

const dropDownConverter = (value: string[]) => {
  const result =
    value?.length > 0 &&
    value?.map((item: any, key: number) => ({
      label: "App Version " + item,
      value: item,
    }))
  return result
}

// const uploadCheck = (name: any, fileInfo: any, type?: any) => {

//   const { dispatch } = store ?? {}

//   const MAX_IMAGE_SIZE_MB = 2;
//   const MAX_AUDIO_SIZE_MB = 5;
//   const MAX_DOCUMENT_SIZE_MB = 10;
//   const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
//   const ALLOWED_AUDIO_TYPES = ['audio/mpeg', 'audio/wav'];
//   const ALLOWED_DOCUMENT_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv'];

//   const maxImageSizeBytes = MAX_IMAGE_SIZE_MB * 1024 * 1024;
//   const maxAudioSizeBytes = MAX_AUDIO_SIZE_MB * 1024 * 1024;
//   const maxDocumentSizeBytes = MAX_DOCUMENT_SIZE_MB * 1024 * 1024;

//   const checkFileSize = (fileSize: number, maxSizeBytes: number, typeDescription: string) => {
//     if (fileSize > maxSizeBytes) {
//       dispatch.commonModel.updateNotificationState({
//         message: 'Failed',
//         description: `${typeDescription} file size exceeds the limit`,
//         type: 'error',
//       });
//       console.error(`${typeDescription} file size exceeds the limit`);
//       return false;
//     }
//     return true;
//   };

//   const checkFileType = (fileType: string, allowedTypes: string[], typeDescription: string) => {
//     console.log('fileType', fileType);

//     if (!allowedTypes.includes(fileType)) {
//       console.log('fileInfo.type', fileInfo);
//       dispatch.commonModel.updateNotificationState({
//         message: 'Failed',
//         description: `Invalid ${typeDescription} file type.`,
//         type: 'error',
//       });
//       return false;
//     }
//     return true;
//   };

//   if (type === 6) { // Image files
//     if (!checkFileSize(fileInfo?.file?.size, maxImageSizeBytes, 'Image') || !checkFileType(fileInfo?.file?.type, ALLOWED_IMAGE_TYPES, 'image')) {
//       return false;
//     }
//   } else if (type === 7) { // Audio files
//     if (!checkFileSize(fileInfo?.file?.size, maxAudioSizeBytes, 'Audio') || !checkFileType(fileInfo?.file?.type, ALLOWED_AUDIO_TYPES, 'audio')) {
//       return false;
//     }
//   } else { // Document files
//     if (!checkFileSize(fileInfo?.file?.size, maxDocumentSizeBytes, 'Document') || !checkFileType(fileInfo?.file?.type, ALLOWED_DOCUMENT_TYPES, 'document')) {
//       return false;
//     }
//   }
// }


const uploadCheck = (type?: any, fileInfo?: any) => {

  const { dispatch } = store ?? {}

  const MAX_IMAGE_SIZE_MB = 2;
  const MAX_AUDIO_SIZE_MB = 5;
  const MAX_DOCUMENT_SIZE_MB = 10;

  const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
  const ALLOWED_AUDIO_TYPES = ['audio/mpeg', 'audio/wav'];
  const ALLOWED_DOCUMENT_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv', 'text/xml','text/plain'];



  if (type == 6) {
    // check file size    
    const maxImageSizeBytes = MAX_IMAGE_SIZE_MB * 1024 * 1024;
    if (fileInfo?.size > maxImageSizeBytes) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'File size exceeds the 2MB limit',
        type: 'error',
      })
      return false;
    }

    // Check file type
    if (!ALLOWED_IMAGE_TYPES.includes(fileInfo?.type)) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Invalid file type. Upload a valid file type.',
        type: 'error',
      })
      return false;
    }
    else {
      return true
    }
  }
  if (type === 7) { // Assuming type 7 represents audio files
    // Check file size for audio
    const maxAudioSizeBytes = MAX_AUDIO_SIZE_MB * 1024 * 1024;
    if (fileInfo?.size > maxAudioSizeBytes) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Audio file size exceeds the 5MB limit',
        type: 'error',
      });
      console.error('Audio file size exceeds the 5MB limit');
      return false;
    }

    // Check file type for audio
    if (!ALLOWED_AUDIO_TYPES.includes(fileInfo?.type)) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Invalid audio file type. Only MP3 and WAV files are allowed',
        type: 'error',
      });
      return false;
    }
    else {
      return true
    }
  }else{    
    const maxDocSizeBytes = MAX_DOCUMENT_SIZE_MB * 1024 * 1024;
    if (fileInfo?.size > maxDocSizeBytes) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Doc file size exceeds the 10MB limit',
        type: 'error',
      });
      console.error('Doc file size exceeds the 10MB limit');
      return false;
    }

    // Check file type for audio
    if (!ALLOWED_DOCUMENT_TYPES.includes(fileInfo?.type)) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Invalid doc file type.',
        type: 'error',
      });
      return false;
    }
    else {
      return true
    }
  }
}

const FilterValues = [
  { label: '==', value: '$eq' },
  { label: '!=', value: '$ne' },
  { label: '>', value: '$gt' },
  { label: '>=', value: '$ge' },
  { label: '<', value: '$lt' },
  { label: '<=', value: '$le' },
  { label: 'in', value: '$in' },
  { label: 'not in', value: '$notin' },
]

const pastTickets = [
  {
    name: 'Default',
    desc: '',
    date: 'June 15, 11.39 PM'
  },
  {
    name: 'Tech Support',
    desc: '',
    date: 'June 15, 11.39 PM'
  }
  , {
    name: 'Sales',
    desc: '',
    date: 'June 15, 11.39 PM'
  }
]

const tickets = [
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  // Add more objects as needed
];

const healthTable = [{
  customer: "Google",
  total_tickets: 175,
  open_tickets: 6,
  escalation: 35,
  time_for_resolve: 47,
  sentiment: 60,
  health: 20
},
{
  customer: "Facebook",
  total_tickets: 175,
  open_tickets: 6,
  escalation: 80,
  sentiment: 90,
  time_for_resolve: 4,
  health: 60
}]


const LanguageOptions = [
  {"display_name": "English", "code": "en_US"},
  {"display_name": "Afrikaans", "code": "af_ZA"},
  {"display_name": "Albanian", "code": "sq_AL"},
  {"display_name": "Arabic", "code": "ar_AE"},
  {"display_name": "Bengali", "code": "bn_BD"},
  {"display_name": "Bahasa Indonesia", "code": "in_ID"},
  {"display_name": "Bosnian", "code": "bs_BA"},
  {"display_name": "Bulgarian", "code": "bg_BG"},
  {"display_name": "Chinese (Simplified)", "code": "zh_CN"},
  {"display_name": "Chinese (Traditional)", "code": "zh_HK"},
  {"display_name": "Croatian", "code": "hr_HR"},
  {"display_name": "Czech", "code": "cs_CZ"},
  {"display_name": "Danish", "code": "da_DK"},
  {"display_name": "Dutch", "code": "nl_NL"},
  {"display_name": "Estonian", "code": "et_EE"},
  {"display_name": "Espanol", "code": "es_ES"},
  {"display_name": "Filipino", "code": "tl_PH"},
  {"display_name": "Finnish", "code": "fi_FI"},
  {"display_name": "French", "code": "fr_FR"},
  {"display_name": "German", "code": "de_DE"},
  {"display_name": "Greek", "code": "el_GR"},
  {"display_name": "Gujarati", "code": "gu_IN"},
  {"display_name": "Hebrew", "code": "he_IL"},
  {"display_name": "Hindi", "code": "hi_IN"},
  {"display_name": "Hungarian", "code": "hu_HU"},
  {"display_name": "Italian", "code": "it_IT"},
  {"display_name": "Japanese", "code": "ja_JP"},
  {"display_name": "Kannada", "code": "kn_IN"},
  {"display_name": "Korean", "code": "ko_KR"},
  {"display_name": "Lithuanian", "code": "lt_LT"},
  {"display_name": "Malay", "code": "ms_MY"},
  {"display_name": "Malayalam", "code": "ml_IN"},
  {"display_name": "Marathi", "code": "mr_IN"},
  {"display_name": "Mongolian", "code": "mn_MN"},
  {"display_name": "Nepali", "code": "ne_NP"},
  {"display_name": "Norwegian", "code": "no_NO"},
  {"display_name": "Pasthu", "code": "ps_AF"},
  {"display_name": "Persian", "code": "fa_IR"},
  {"display_name": "Punjabi", "code": "pa_IN"},
  {"display_name": "Polish", "code": "pl_PL"},
  {"display_name": "Portugese", "code": "pt_PT"},
  {"display_name": "Romanian", "code": "ro_RO"},
  {"display_name": "Russian", "code": "ru_RU"},
  {"display_name": "Serbian", "code": "sr_RS"},
  {"display_name": "Slovak", "code": "sk_SK"},
  {"display_name": "Slovenian", "code": "sl_SI"},
  {"display_name": "Swahili", "code": "sw_TZ"},
  {"display_name": "Swedish", "code": "sv_SE"},
  {"display_name": "Tamil", "code": "ta_IN"},
  {"display_name": "Telugu", "code": "te_IN"},
  {"display_name": "Thai", "code": "th_TH"},
  {"display_name": "Turkish", "code": "tr_TR"},
  {"display_name": "Ukrainian", "code": "uk_UA"},
  {"display_name": "Urdu", "code": "ur_PK"},
  {"display_name": "Vietnamese", "code": "vi_VN"},
  {"display_name": "Welsh", "code": "cy_GB"},
  {"display_name": "Zulu", "code": "zu_ZA"}
]



function getAllValues() {

  return {
      currentMonthStartDate: 1577817000000,
      currentMonthEndDate: 1893436200000,
      previousMonthStartDate: 1577817000000,
      previousMonthEndDate: 1893436200000
  };
}

function getMonthRangeConversation() {
  const today = new Date();
  const thirtyOneDaysAgo = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  // Subtract 31 days from the current date
  thirtyOneDaysAgo.setDate(today.getDate() - 31);


  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  
  return {
    currentMonthStartDate: thirtyOneDaysAgo.getTime(),
    currentMonthEndDate: nextDay.getTime()
  };
}

function getMonthRange() {
  // const today = new Date();
  // const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  // return {
  //     startDate: oneMonthAgo.getTime(),
  //     endDate: today.getTime()
  // };


  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  const oneWeekAgo = new Date(today.getTime());
  oneWeekAgo.setDate(today.getDate() - 31); // Set to exactly one week ago

  const twoWeeksAgo = new Date(oneWeekAgo.getTime());
  twoWeeksAgo.setDate(oneWeekAgo.getDate() - 31); // Set to exactly two weeks ago

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  // const today = new Date();
  // const currentMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
  // const currentMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month

  // const previousMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1); // First day of the previous month
  // const previousMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

  // return {
  //   currentMonthStartDate: 1675189800000,
  //   currentMonthEndDate: 1682879400000,
  //   previousMonthStartDate: 1669833000000,
  //   previousMonthEndDate: 1675189800000
  // }
  return {
      currentMonthStartDate: oneWeekAgo.getTime(),
      currentMonthEndDate: nextDay.getTime(),
      previousMonthStartDate: twoWeeksAgo.getTime(),
      previousMonthEndDate: oneWeekAgo.getTime()
  };
}
console.log(getMonthRange());


function getThreeMonthRange() {
  // const today = new Date();
  // const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  // return {
  //     startDate: oneMonthAgo.getTime(),
  //     endDate: today.getTime()
  // };
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  const oneWeekAgo = new Date(today.getTime());
  oneWeekAgo.setDate(today.getDate() - 90); // Set to exactly one week ago

  const twoWeeksAgo = new Date(oneWeekAgo.getTime());
  twoWeeksAgo.setDate(oneWeekAgo.getDate() - 90); // Set to exactly two weeks ago

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);
  // const today = new Date();
  // const currentMonthStartDate = new Date(today.getFullYear(), today.getMonth()-2, 1); // First day of the current month
  // const currentMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month

  // const previousMonthStartDate = new Date(today.getFullYear(), today.getMonth() -1, 1); // First day of the previous month
  // const previousMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

  // return {
  //   currentMonthStartDate: 1675189800000,
  //   currentMonthEndDate: 1682879400000,
  //   previousMonthStartDate: 1669833000000,
  //   previousMonthEndDate: 1675189800000
  // }
  return {
      currentMonthStartDate: oneWeekAgo.getTime(),
      currentMonthEndDate: nextDay.getTime(),
      previousMonthStartDate: twoWeeksAgo.getTime(),
      previousMonthEndDate: oneWeekAgo.getTime()
  };
}



function getWeekRange() {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  const oneWeekAgo = new Date(today.getTime());
  oneWeekAgo.setDate(today.getDate() - 7); // Set to exactly one week ago

  const twoWeeksAgo = new Date(oneWeekAgo.getTime());
  twoWeeksAgo.setDate(oneWeekAgo.getDate() - 7); // Set to exactly two weeks ago

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  // return {
  //   currentMonthStartDate: 1675189800000,
  //   currentMonthEndDate: 1677609000000,
  //   previousMonthStartDate: 1672511400000,
  //   previousMonthEndDate: 1675189800000
  // }
  return {
    currentMonthStartDate: oneWeekAgo.getTime(),
    currentMonthEndDate: nextDay.getTime(),
    previousMonthStartDate: twoWeeksAgo.getTime(),
    previousMonthEndDate: oneWeekAgo.getTime()
  };
}

function getDayRange() {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set today's date to midnight to ensure the full day is covered

  const oneDayAgo = new Date(today.getTime());
  oneDayAgo.setDate(today.getDate() - 1); // Set to exactly one day ago at midnight

  const twoDaysAgo = new Date(oneDayAgo.getTime());
  twoDaysAgo.setDate(oneDayAgo.getDate() - 1); // Set to two days ago from today at midnight

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  // return {
  //   currentMonthStartDate: 1676917800000,
  //   currentMonthEndDate: 1677609000000,
  //   previousMonthStartDate: 1676313000000,
  //   previousMonthEndDate: 1676917800000
  // }
  // return {
  //   currentMonthStartDate: oneDayAgo,  // Adjust as needed to return the correct start date
  //   currentMonthEndDate: nextDay       // Adjust to return the correct end date
  // };


  return {
    currentMonthStartDate: today.getTime(),
    currentMonthEndDate: nextDay.getTime(),
    previousMonthStartDate: twoDaysAgo.getTime(),
    previousMonthEndDate: oneDayAgo.getTime()
  };


}
function getCurrentTimeChatBot() {
  const date = new Date();
  return format(date, 'dd MMM yyyy HH:mm');
}

function convertMillisToDateString(milliseconds: any) {
  // Create a new Date object with the given milliseconds
  const date = new Date(milliseconds);

  // Array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day of the month
  const day = date.getDate();

  // Get the month name from the monthNames array
  const month = monthNames[date.getMonth()];

  // Get the last two digits of the year
  const year = date.getFullYear().toString().slice(-2);

  // Return the formatted string
  return `${day} ${month} ${year}`;
}

function getFormatedDate(timestamp: any)  {
   const date = new Date(timestamp);

  // Format the date and time
  const formattedDate = date.toLocaleString('en-US', {
      month: 'long', // Full month name
      day: 'numeric', // Numeric day
      year: 'numeric', // Full year
      hour: 'numeric', // Hour in 12-hour format
      minute: 'numeric', // Minutes
      // second: 'numeric', // Seconds
      hour12: true // 12-hour format
  });

  return formattedDate.replace('at', ''); // Remove the comma after the day
}

const getQuestion = (question: string) => {
  
  const regex = new RegExp(
    `^Default language : [^;]+;(.+)$`
  );
  const match = question.match(regex);
  if (match) {
    return match[1].trim();
  } else {
    return question; // or handle the error appropriately
  }
};

export {
  routerHistory,
  extractIdToken,
  getUserRoleFromToken,
  getTenantNameFromToken,
  setRouterHistory,
  convertBytestoMB,
  statusChecker,
  uploadToLocalStorage,
  getFromLocalStorage,
  downloadBlob,
  getValueFromLocalStorage,
  addValueToLocalStorage,
  getTenantId,
  getFileType,
  generateNodeSequenceResponse,
  dropDownConverter,
  getAccessLists,
  FilterValues,
  pastTickets,
  tickets,
  getUserRole,
  getMonthRangeConversation,
  getMonthRange,
  getWeekRange,
  getDayRange,
  healthTable,
  getTenantIdwithtokenId,
  getCurrentTimeChatBot,
  uploadCheck,
  convertMillisToDateString,
  getFormatedDate,
  getAllValues,
  LanguageOptions,
  getQuestion,
  getThreeMonthRange,
  PaginationLimit
}
