import styled from 'styled-components'
import ExtensionLogo from '../assets/icons/ExtensionLogo'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import TickIconWithBorder from '../assets/icons/TickIconwithBorder'
import { useLocation } from 'react-router-dom'
import { Card, Layout } from 'antd'
import WizrHeadingText from '../components/WizrHeadingText'
import WizrButton from '../components/WizrButton'

const LoginCard = styled(Card)`
  width: 30%;
  border-radius: 16px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1) !important;
  .ant-card-head {
    border: none !important;
  }
  .ant-card-head-title {
    color: var(--grayscale-900, #1d1f22);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`

const StyledLayout = styled(Layout)`
  background: transparent !important;
  margin-top: 15px;
`

const ExtensionRedirectScreen = (): JSX.Element => {
  // const StyledWizrFlexLayout = styled(WizrFlexLayout)`
  // background-color:red !important;

  // maxWidth:"50%" !important;
  // maxHeight:"50%" !important;
  // `

  const isForgotPassword = useLocation().pathname === '/extension/login'

  const StyledWizrFlexLayout = styled.div`
    display: flex;
    height: 60%;
    width: 50%;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    flex-direction: column;
    padding: 2% 5%;
    gap: 30px;
  `


  const startB2CAuthentication = () => {
    const authUrl = `${process.env.REACT_APP_API_EXTENSION_LOGIN_URL}`
    window.location.href = authUrl
  }



  return (
    <WizrFlexLayout  justifyContent='center' alignItems='center'>
      {/* <WizrFlexLayout
        alignItems='center'
        style={{ padding: '40px', gap: '40px' }}
        justifyContent='flex-start'
      >
        <ExtensionLogo />
        <WizrText type='h6' textColor='black'>
          We are excited to have you{' '}
        </WizrText>
        <WizrText type='h4'>Make your customer service super-human</WizrText>
        <WizrText type='body1'>
          Convert customer support to revenue center using Generative AI{' '}
        </WizrText>
        <WizrText type='body1'>Feel free to close this tab </WizrText>
      </WizrFlexLayout> */}
      {!isForgotPassword?<StyledWizrFlexLayout id='wizr_extension_redirect'>
        {/* <WizrFlexLayout style={{maxWidth:"60%"}} justifyContent='center' alignItems='center' background='white'> */}
        <ExtensionLogo />
        {/* </WizrFlexLayout> */}
        {/* <WizrFlexLayout style={{maxWidth:"60%"}} justifyContent='center' alignItems='center' background='white'> */}
        <TickIconWithBorder />
        <WizrFlexLayout background='white' style={{ maxHeight: '65px' }}>
          <WizrText type='sub1' textColor='black'>
            {' '}
            Welcome back!{' '}
          </WizrText>
          <WizrText type='cardData' textColor='black'>
            {' '}
            Your login was successful, and we’ve received the details of your tool.
            <br />
            Please close the window and return to your ticket system to use extention.
          </WizrText>
          {/* </WizrFlexLayout> */}
        </WizrFlexLayout>
      </StyledWizrFlexLayout>:<StyledWizrFlexLayout>
        {/* <WizrFlexLayout style={{maxWidth:"60%"}} justifyContent='center' alignItems='center' background='white'> */}
        <ExtensionLogo />
        {/* </WizrFlexLayout> */}
        {/* <WizrFlexLayout style={{maxWidth:"60%"}} justifyContent='center' alignItems='center' background='white'> */}
        <WizrFlexLayout background='white' style={{ maxHeight: '100px' ,justifyContent:"center",alignItems:"center",gap:"10px"}}>
          <WizrText type='sub1' textColor='black'>
            {' '}
            Password reset successful!{' '}

          </WizrText>
          <WizrText type='sub1' textColor='black'>
          Your session needs to be refreshed. Kindly log out and log in again.
            <br />
          </WizrText>

          <WizrButton text='Login' onClick={startB2CAuthentication}  style={{width:"20%"}}/>

          {/* </WizrFlexLayout> */}
        </WizrFlexLayout>
      </StyledWizrFlexLayout>}
    </WizrFlexLayout>
    // </WizrFlexLayout>
  )
}


export default ExtensionRedirectScreen
