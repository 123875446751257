import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrTextArea from '../WizrTextArea'
import { useEffect, useState } from 'react'
import WizrTag from '../WizrTags'

interface IAddExamplesForm {
  parameterList?: any
  fileName?: string
  onSubmit?: any
  onCancel?: () => void
  exampleInput?: any
}

const StyledWizrFlexSpacing = styled(WizrFlexSpacing)`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
`
const StyledFlexSpacing = styled(WizrFlexSpacing)`
.ant-space-item {
  display: flex !important;
  width: 100% !important;
  flex-wrap: wrap;
}
`
const StyledWizrTag = styled(WizrTag)`
height: 42px;
padding: 8px 11px;
justify-content: space-between;
`

const AddExamplesForm = ({
  parameterList,
  onCancel,
  onSubmit,
  exampleInput = null,
}: IAddExamplesForm): JSX.Element => {
  const [examplesData, setExamplesData] = useState<{ user: string; assistant: string }>({
    user: '',
    assistant: '',
  })

  useEffect(() => {
    
    exampleInput && setExamplesData(exampleInput)
  }, [])
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  const handleCursorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
  };

  return (
    <WizrFlexLayout
      flexDirection='coloumn'
      background='#FFF'
      justifyContent='space-evenly'
      alignItems='flex-start'
    >
      <WizrText type='body2'>Human</WizrText>
      <br />
      <WizrTextArea
        value={examplesData.user}
        onChange={(value: any) => setExamplesData({ ...examplesData, user: value })}
        onSelect={handleCursorChange}
      />
      <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20} background='white'>
            <StyledWizrTag
              background="grey"
              data={parameterList}
              onClick={(e: any) => {
                
                const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value
                
                
                if (cursorPosition !== null) {
                  // Insert the clicked tag value at the cursor position
                  const updatedSystemMessage =
                    `${examplesData?.user.slice(0, cursorPosition)}{{${clickedTagValue}}}${examplesData?.user.slice(cursorPosition)}`

                  // Update the systemMessage state with the modified value
                  setExamplesData({ ...examplesData, user: updatedSystemMessage })

                  // Move the cursor position to the end of the inserted value
                  setCursorPosition(cursorPosition + clickedTagValue.length);  }              
              }}
            />
          </StyledFlexSpacing>
      <br />
      <WizrText type='body2'>Assistant</WizrText>
      <br />
      <WizrTextArea
        value={examplesData.assistant}
        onSelect={handleCursorChange}
        onChange={(value: any) => setExamplesData({ ...examplesData, assistant: value })}
      />
       <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20} background='white'>
            <StyledWizrTag
              background="grey"
              data={parameterList}
              onClick={(e: any) => {
                
                const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value

                if (cursorPosition !== null) {
                  // Insert the clicked tag value at the cursor position
                  const updatedSystemMessage =
                    `${examplesData.assistant.slice(0, cursorPosition)}{{${clickedTagValue}}}${examplesData.assistant.slice(cursorPosition)}`

                  // Update the systemMessage state with the modified value
                  setExamplesData({ ...examplesData, assistant: updatedSystemMessage })

                  // Move the cursor position to the end of the inserted value
                  setCursorPosition(cursorPosition + clickedTagValue.length);  }              
              }}
            />
          </StyledFlexSpacing>
      <br />
      <StyledWizrFlexSpacing
        justifyContent='flex-end'
        flexDirection='row'
        size='large'
        background='#FFF'
      >
        <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '12px'}}/>
        <WizrButton text={exampleInput ? 'Save' : 'Add'} onClick={() => onSubmit(examplesData)} style={{borderRadius: '12px'}}/>
      </StyledWizrFlexSpacing>
    </WizrFlexLayout>
  )
}

export default AddExamplesForm
